var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-fill mt-3 animate__animated animate__fadeInUp"},[(_vm.bills.length > 0)?_c('div',{staticClass:"d-flex flex-column flex-grow-1 grey lighten-4 pa-3",staticStyle:{"border-radius":"3px"}},[_c('v-expansion-panels',{attrs:{"flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"elevation-0"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1",staticStyle:{"justify-content":"space-between"}},[_c('span',{staticClass:" font font-size-sm  mt-1 text-uppercase font-weight-bold",staticStyle:{"border-radius":"3px"}},[_vm._v("Bills")])])]),_c('v-expansion-panel-content',[_c('v-slide-y-transition',{attrs:{"group":""}},_vm._l((_vm.bills),function(bill,index){return _c('div',{key:index,staticClass:"d-flex flex-column flex-grow-1 my-1 white px-2 py-3 ma-2 box-shadow-light"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 pl-3 pr-3 pt-3 pb-1"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('validationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font font-weight-medium font-size-md pa-1",attrs:{"label":"Bill Name","dense":"","error-messages":errors[0],"prepend-icon":"loyalty"},model:{value:(bill.name),callback:function ($$v) {_vm.$set(bill, "name", $$v)},expression:"bill.name"}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required|decimals"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font font-weight-medium font-size-md pa-1",attrs:{"prepend-icon":"credit_card","label":"Total Amount","prefix":_vm.currency && _vm.currency.length > 0
                          ? _vm.currency[0].symbol
                          : 'GHS',"error-messages":errors[0],"dense":""},model:{value:(bill.amount),callback:function ($$v) {_vm.$set(bill, "amount", $$v)},expression:"bill.amount"}})]}}],null,true)})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.removeBillRow(index)}}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("delete")])])]}}],null,true)},[_c('span',{staticClass:" font font-weight-medium font-size-sm"},[_vm._v("Remove Item")])])],1)])}),0),(_vm.totalFuelRecordings === 0)?_c('v-alert',{staticClass:"mx-2 mt-2",attrs:{"dense":"","type":"warning"}},[_c('span',{staticClass:"font font-weight-medium font-size-sm"},[_vm._v("Seems no fuel nozzle readings or credit collections have been added ")])]):_vm._e(),(!_vm.isAccountsTallied)?_c('v-alert',{staticClass:"mx-2 mt-2",attrs:{"dense":"","type":"error"}},[_c('span',{staticClass:"font font-weight-medium font-size-sm"},[_vm._v("Account not tallied, kindly reaccess. Amount can be +/- 15 cedis ")])]):_vm._e(),_c('div',{staticClass:"d-flex flex-row flex-grow-1 ",staticStyle:{"justify-content":"flex-end"}},[_c('v-btn',{staticClass:"font font-weight-medium mr-2 mt-2",staticStyle:{"font-size":"12px"},attrs:{"color":"primary","small":"","disabled":_vm.sum(_vm.recordedFuelSalesList, 'totalAmountSold') +
                  _vm.sum(
                    _vm.pumps.reduce(function (acc, pump) { return acc.concat(pump); }, []),
                    'totalAmountSold'
                  ) +
                  _vm.totalCreditCollection <=
                  _vm.totalFuelSales},on:{"click":function($event){$event.stopPropagation();return _vm.addBillRow($event)}}},[_vm._v(" Add New Bill ")])],1)],1)],1)],1)],1):_c('div',{staticClass:"d-flex flex-column flex-grow-1 my-1 justify-center grey lighten-4 pa-5",staticStyle:{"align-items":"center"}},[_c('img',{staticStyle:{"width":"80px"},attrs:{"src":require("@/assets/bill.svg")}}),_c('span',{staticClass:"font font-weight-medium"},[_vm._v("Add any other bills from managing director (MD) "),_c('kbd',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.date))]),_vm._v(".")]),_c('span',{staticClass:"font font-size-sm font-weight-medium"},[_vm._v(" Bill may include maintenance on cars, trucks etc ")]),_c('v-btn',{staticClass:"mt-3",attrs:{"disabled":_vm.sum(_vm.recordedFuelSalesList, 'totalAmountSold') +
          _vm.sum(
            _vm.pumps.reduce(function (acc, pump) { return acc.concat(pump); }, []),
            'totalAmountSold'
          ) +
          _vm.totalCreditCollection <=
          _vm.totalFuelSales,"small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.addBillRow($event)}}},[_c('span',{staticClass:"font font-weight-medium font-size-sm",staticStyle:{"font-size":"12px !important"}},[_vm._v("Add Bill")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }