<template>
  <div
    class="d-flex flex-column flex-grow-1 flex-fill mt-3 animate__animated animate__fadeInUp"
  >
    <div
      class="d-flex flex-column flex-grow-1 grey lighten-4 pa-3"
      style="border-radius: 3px;"
      v-if="bills.length > 0"
    >
      <v-expansion-panels flat>
        <v-expansion-panel>
          <v-expansion-panel-header class="elevation-0"
            ><div
              class="d-flex flex-row flex-grow-1"
              style="justify-content: space-between;"
            >
              <span
                class=" font font-size-sm  mt-1 text-uppercase font-weight-bold"
                style="border-radius: 3px;"
                >Bills</span
              >
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-slide-y-transition group>
              <div
                v-for="(bill, index) in bills"
                :key="index"
                class="d-flex flex-column flex-grow-1 my-1 white px-2 py-3 ma-2 box-shadow-light"
              >
                <div class="d-flex flex-row flex-grow-1 pl-3 pr-3 pt-3 pb-1">
                  <div class="d-flex flex-row flex-grow-1">
                    <validationProvider
                      tag="div"
                      class="d-flex flex-row flex-grow-1"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        label="Bill Name"
                        dense
                        :error-messages="errors[0]"
                        class="font font-weight-medium font-size-md pa-1"
                        v-model="bill.name"
                        prepend-icon="loyalty"
                      />
                    </validationProvider>
                  </div>

                  <div class="d-flex flex-row flex-grow-1">
                    <ValidationProvider
                      tag="div"
                      class="d-flex flex-row flex-grow-1"
                      rules="required|decimals"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        prepend-icon="credit_card"
                        label="Total Amount"
                        v-model="bill.amount"
                        :prefix="
                          currency && currency.length > 0
                            ? currency[0].symbol
                            : 'GHS'
                        "
                        :error-messages="errors[0]"
                        class="font font-weight-medium font-size-md pa-1"
                        dense
                      />
                    </ValidationProvider>
                  </div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        small
                        color="red"
                        @click.stop="removeBillRow(index)"
                      >
                        <i class="material-icons-outlined">delete</i>
                      </v-btn>
                    </template>
                    <span class=" font font-weight-medium font-size-sm"
                      >Remove Item</span
                    >
                  </v-tooltip>
                </div>
              </div>
            </v-slide-y-transition>
            <v-alert
              v-if="totalFuelRecordings === 0"
              dense
              type="warning"
              class="mx-2 mt-2"
            >
              <span class="font font-weight-medium font-size-sm"
                >Seems no fuel nozzle readings or credit collections have been
                added
              </span>
            </v-alert>
            <v-alert
              v-if="!isAccountsTallied"
              dense
              type="error"
              class="mx-2 mt-2"
            >
              <span class="font font-weight-medium font-size-sm"
                >Account not tallied, kindly reaccess. Amount can be +/- 15
                cedis
              </span>
            </v-alert>

            <div
              class="d-flex flex-row flex-grow-1 "
              style="justify-content: flex-end;"
            >
              <v-btn
                color="primary"
                style="font-size: 12px;"
                small
                :disabled="
                  sum(recordedFuelSalesList, 'totalAmountSold') +
                    sum(
                      pumps.reduce((acc, pump) => acc.concat(pump), []),
                      'totalAmountSold'
                    ) +
                    totalCreditCollection <=
                    totalFuelSales
                "
                @click.stop="addBillRow"
                class="font font-weight-medium mr-2 mt-2"
              >
                Add New Bill
              </v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div
      v-else
      class="d-flex flex-column flex-grow-1 my-1 justify-center grey lighten-4 pa-5"
      style="align-items:center;"
    >
      <img src="@/assets/bill.svg" style="width: 80px;" />
      <span class="font font-weight-medium"
        >Add any other bills from managing director (MD)
        <kbd class="mr-2">{{ date }}</kbd
        >.</span
      >
      <span class="font font-size-sm font-weight-medium">
        Bill may include maintenance on cars, trucks etc
      </span>
      <v-btn
        :disabled="
          sum(recordedFuelSalesList, 'totalAmountSold') +
            sum(
              pumps.reduce((acc, pump) => acc.concat(pump), []),
              'totalAmountSold'
            ) +
            totalCreditCollection <=
            totalFuelSales
        "
        @click.stop="addBillRow"
        small
        color="primary"
        class="mt-3"
      >
        <span
          class="font font-weight-medium font-size-sm"
          style="font-size: 12px !important;"
          >Add Bill</span
        >
      </v-btn>
    </div>
  </div>
</template>
<script>
import { currencyFormat } from "../../../filters/currencyFormat";
import { ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { decimals } from "../../utils/resolvers";
extend("required", {
  ...required,
  message: "Input field is required"
});
extend(decimals, decimals);

export default {
  name: "RecordedMDsBills",
  components: {
    ValidationProvider
  },
  props: {
    currency: { type: Array, default: () => [] },
    date: {
      type: String,
      default: null
    },
    bills: {
      type: Array,
      default: () => []
    },
    pumps: {
      type: Array,
      default: () => []
    },
    loading: Boolean,
    totalFuelSales: {
      type: Number,
      default: 0
    },
    recordedFuelSalesList: {
      type: Array,
      default: () => []
    },
    totalCreditCollection: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    editing: null,
    model: null,
    isAccountsTallied: true,
    totalFuelRecordings: 0
  }),
  watch: {
    totalFuelSales(value) {
      this.totalFuelRecordings =
        this.sum(this.recordedFuelSalesList, "totalAmountSold") +
        this.sum(
          this.pumps.reduce((acc, pump) => acc.concat(pump), []),
          "totalAmountSold"
        ) +
        this.totalCreditCollection;
      const balance = this.totalFuelRecordings - value;
      this.isAccountsTallied = balance >= -15;
    },
    totalCreditCollection(value) {
      this.totalFuelRecordings =
        this.sum(this.recordedFuelSalesList, "totalAmountSold") +
        this.sum(
          this.pumps.reduce((acc, pump) => acc.concat(pump), []),
          "totalAmountSold"
        ) +
        value;
      this.isAccountsTallied =
        this.totalFuelRecordings - this.totalFuelSales >= -15;
    }
  },
  methods: {
    sum(items, key) {
      return items.reduce(
        (acc, item) =>
          (acc += isNaN(parseFloat(item[key])) ? 0 : parseFloat(item[key])),
        0
      );
    },
    edit(index, item) {
      if (!this.editing) {
        this.editing = { ...item };
      } else {
        this.$store.dispatch("currency/updateModeOfPayment", {
          ...this.editing
        });
        this.editing = null;
      }
    },
    addBillRow() {
      this.$emit("addBillRow", { name: "", amount: 0 });
    },
    removeBillRow(index) {
      this.bills.splice(index, 1);
    }
  },
  filters: {
    currencyFormat
  }
};
</script>
